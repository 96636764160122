import { LoadingButton } from '@mapped/rivet/dist/mui/lab'
import { Box, TextField, Typography } from '@mapped/rivet/dist/mui/material'
import { styled } from '@mapped/rivet/dist/mui/styles'
import Head from 'next/head'
import Link from 'next/link'
import { FormEvent, FunctionComponent, useEffect, useState } from 'react'
import * as auth0 from '../auth/auth0'
import { AuthPageLayout } from '../components/auth/pageLayout'
import {
  AuthProviderButtonsMap,
  EAuthProvider,
} from '../components/auth/providerButton'
import { useNotifications } from '../hooks/useNotifications'
import { getUrlParam } from '../utils/url'

const LoginPage: FunctionComponent = () => {
  const notifications = useNotifications()

  const [selectedProvider, setSelectedProvider] = useState(EAuthProvider.EMAIL)

  const sso = selectedProvider === EAuthProvider.SSO

  useEffect(() => {
    const error = getUrlParam<string>('error', '')

    if (error) {
      notifications.push({ type: 'error', message: error })
    }
  }, [])

  return (
    <>
      <Head>
        <title>Mapped Console • Sign in</title>
      </Head>

      <AuthPageLayout title="Sign in" hideFooter={true}>
        <LoginForm
          selectedProvider={selectedProvider}
          onChangeSelectedProvider={setSelectedProvider}
        />

        {sso ? (
          <CTA>
            <Link
              href={window.location.href}
              onClick={() => setSelectedProvider(EAuthProvider.EMAIL)}
            >
              Back to login options
            </Link>
          </CTA>
        ) : (
          <>
            <CTA>
              <Link href="/forgot-password">Forgot your password?</Link>
            </CTA>

            <CTA style={{ marginTop: 15 }}>
              Don't have an account? <Link href="/signup">Sign up</Link>
            </CTA>

            <Box height={80} />
          </>
        )}
      </AuthPageLayout>
    </>
  )
}

export const LoginForm: FunctionComponent<{
  selectedProvider?: EAuthProvider
  onChangeSelectedProvider: (p: EAuthProvider) => void
}> = ({ selectedProvider, onChangeSelectedProvider }) => {
  const notifications = useNotifications()
  const sso = selectedProvider === EAuthProvider.SSO

  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  function onSubmit(e?: FormEvent, provider = EAuthProvider.EMAIL) {
    e?.preventDefault()

    onChangeSelectedProvider(provider)
    setIsLoading(true)

    switch (provider) {
      case EAuthProvider.GOOGLE:
      case EAuthProvider.MICROSOFT:
      case EAuthProvider.GITHUB:
        return auth0.authorizeWithSocial(provider, {})

      case EAuthProvider.SSO:
        return fetch(`/api/sso/${email.split('@')[1]}`, { method: 'POST' })
          .then((res) => res.json())
          .then(({ connection }) => {
            if (!connection) {
              setIsLoading(false)

              return notifications.push({
                type: 'error',
                message: 'SSO is not enabled for this domain',
              })
            }

            return auth0.authorizeWithSocial(EAuthProvider.SSO, {
              connection,
            })
          })

      case EAuthProvider.EMAIL:
        return auth0
          .authorizeWithCredentials({ email, password })
          .then((res) => {
            setIsLoading(!res.error)
            notifications.push({ type: 'error', message: res.error })
          })
    }
  }

  function renderAuthButton(provider: EAuthProvider) {
    const Button = AuthProviderButtonsMap[provider]

    return (
      <Button
        prefix="Sign in with"
        loading={isLoading && selectedProvider === provider}
        disabled={isLoading && selectedProvider !== provider}
        onClick={() => onSubmit(undefined, provider)}
      />
    )
  }

  return (
    <>
      {!sso && (
        <>
          {renderAuthButton(EAuthProvider.MICROSOFT)}
          {renderAuthButton(EAuthProvider.GITHUB)}

          <SSOButton
            prefix="Sign in with"
            onClick={() => onChangeSelectedProvider(EAuthProvider.SSO)}
          />

          <Typography
            paragraph={true}
            sx={(theme) => ({
              fontSize: '16px',
              color: theme.palette.text.a50,
              margin: '16px 0',
              textAlign: 'center',
            })}
          >
            or
          </Typography>
        </>
      )}

      <Box
        sx={{
          form: {
            margin: 0,
            '.MuiInputBase-root': {
              height: '35px',
            },
            '.MuiTextField-root': {
              marginBottom: '11px',
            },
          },
        }}
      >
        <form id="loginForm" onSubmit={(e) => onSubmit(e, selectedProvider)}>
          <TextField
            type="email"
            name="email"
            placeholder="Email"
            size="small"
            fullWidth={true}
            required={true}
            disabled={isLoading}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {!sso && (
            <>
              <TextField
                type="password"
                name="password"
                placeholder="Password"
                size="small"
                fullWidth={true}
                required={true}
                disabled={isLoading}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <LoadingButton
                type="submit"
                disabled={isLoading}
                loading={isLoading && selectedProvider === EAuthProvider.EMAIL}
                fullWidth={true}
                size="medium"
              >
                Sign in
              </LoadingButton>
            </>
          )}

          {!!sso && (
            <SSOButton
              type="submit"
              disabled={isLoading}
              loading={isLoading}
              prefix="Sign in with"
              style={{ marginTop: 0 }}
            />
          )}
        </form>
      </Box>
    </>
  )
}

const SSOButton = AuthProviderButtonsMap[EAuthProvider.SSO]

const CTA = styled.p(({ theme }) => ({
  color: theme.palette.text.primary,
  margin: 0,
  marginTop: '30px',

  a: {
    color: theme.palette.primary.main,
    ':hover': {
      textDecoration: 'underline',
    },
  },
}))

export default LoginPage
