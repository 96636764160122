import { ResponsiveContainer } from '@mapped/rivet/dist/mapped/responsive/container'
import { styled } from '@mapped/rivet/dist/mui/styles'
import { CSSProperties, FunctionComponent, ReactNode } from 'react'
import SVG from 'react-inlinesvg'

export const AuthPageLayout: FunctionComponent<IAuthPageLayout> = ({
  title,
  description,
  children,
  contentSpacing,
  style,
  renderRight,
  width = 388,
}) => {
  return (
    <Container style={style}>
      <ResponsiveContainer style={{ height: '100%' }}>
        <Content>
          <div style={{ width }}>
            <Logo>
              <a
                href="https://www.mapped.com/"
                target="_blank"
                title="Learn more about Mapped"
              >
                <SVG src="/img/mapped-logo-wide-blue.svg" />
              </a>
            </Logo>

            <Title>{title}</Title>

            <div style={{ height: contentSpacing || 30 }} />
            {!!description && (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            )}

            {children}
          </div>

          {renderRight}
        </Content>
      </ResponsiveContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-image: url('/img/auth-background.svg');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position-x: 130%;
  background-position-y: 24px;
`

const Content = styled.div`
  position: relative;
  padding-top: 24px;
  height: 100%;
  display: flex;
  justify-content: space-between;
`

const Logo = styled.div`
  width: 269px;
  margin-bottom: 50px;

  svg,
  a {
    width: 100%;
    height: 100%;

    #lettering,
    g[fill='#FFFFFF'] {
      fill: ${(props) => props.theme.palette.text.primary};
    }
  }
`

const Title = styled.h1`
  font-size: 42px;
  margin: 0;
  font-weight: 500;
`

const Description = styled.p`
  color: black;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
  margin-top: 17px;
`

interface IAuthPageLayout {
  title: string
  width?: number
  description?: string
  hideFooter?: boolean
  contentSpacing?: number
  style?: CSSProperties
  renderRight?: ReactNode
  children: ReactNode
}
